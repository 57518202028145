import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Icon from '../components/Icon';
import CareerCallout from './partials/_CareerCallout';
import EpisodePlayer from '../components/EpisodePlayer';
import HeroV2 from '../components/HeroV2';

const textOverlay = (
  <div className="vts__text_overlay_container">
    <Container fullWidth={true}>
      <div className="vts__text_overlay_inner_container">
        <h2 className="vts__text_overlay_heading">Video Topic Series</h2>
        {/* <p>
          Lorem Ipsum dolar. Lorem ipsum dolor sit amet, consectetur adipiscing
          elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.
          Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis
          ipsum.
        </p> */}
        <div className="vts__text_overlay_link_container">
          <div style={{ marginRight: '1rem' }}>
            <a href="#Data-for-Good">Data For Good</a>
            <Icon
              name="arrowdown"
              marginLeft
              className="vts__text_overlay_link_icon"
            />
          </div>
          <div>
            <a href="#Behind-the-Network">Behind the Network</a>
            <Icon
              name="arrowdown"
              marginLeft
              className="vts__text_overlay_link_icon"
            />
          </div>
        </div>
      </div>
    </Container>
  </div>
);

const VideoTopicPage = ({ data }) => (
  <Layout title="Video Topic Series">
    <HeroV2
      mediaSource="VTS-hero-img-1400x366-2x.jpg"
      textOverlay={textOverlay}
    />
    <Section className="pb-0">
      <Container id="Data-for-Good">
        <Row>
          <Column>
            <h1 className="hero__heading">Video Topic Series: Data for Good</h1>
          </Column>
        </Row>
        <Row>
          <Column size={8}>
            <p>
              Give students a rare look into the latest technological advances
              and highlight two of Mastercard’s top female leaders in Data
              Science and AI/Blockchain.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container fullWidth>
        <Row>
          <EpisodePlayer
            className="pt-0"
            episodes={data.allVideoTopicsYaml.edges
              .map((edge) => edge.node)
              .filter((edge) => edge.section === 'data-for-good')}
            intro={
              <>
                <h3 className="pt-3">Meet Innovative Women Making Change</h3>
                <p>
                  Each topic video introduces students to innovative female
                  professionals who share the positive impacts of their work.
                  Students will discover how data is a superpower that can be
                  used to improve lives and learn about the AI/Blockchain
                  research that is transforming the world. They will also
                  explore how the uses of technologies are driven by individual
                  or societal needs, desires, and values.
                </p>
              </>
            }
          />
        </Row>
      </Container>
    </Section>
    <Section>
      <hr className="vts__horizontal_line" />
    </Section>
    <Section>
      <Container id="Behind-the-Network">
        <Row>
          <Column>
            <h1 className="hero__heading">
              Video Topic Series: Behind the Network
            </h1>
          </Column>
        </Row>
        <Row>
          <Column size={8}>
            <p>
              Give students a behind-the-scenes look into the exciting world of
              cybersecurity to learn how multi-talented professionals keep our
              information and networks safe 24/7.
              {/* <Button
                link
                target="_blank"
                rel="noopener noreferrer"
                to="/docs/Mastercard-Girls4Tech-CyberSecurityEdGuide.pdf"
              >
                <Icon marginLeft marginRight name="download" />
                Educator Guide
              </Button> */}
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container fullWidth>
        <Row>
          <EpisodePlayer
            className="pt-0"
            showDropdown
            episodes={data.allVideoTopicsYaml.edges
              .map((edge) => edge.node)
              .filter((edge) => edge.section === 'behind-the-network')}
            intro={
              <>
                <h3 className="pt-3">Meet Real Women Keeping Us Safe Online</h3>
                <p>
                  Each topic video introduces students to the real faces of
                  cybersecurity professionals who are rising to the occasion in
                  a quickly evolving STEM field. Along the way, students will
                  learn how the fundamentals of cybersecurity can be applied in
                  their own lives to ensure privacy and safety.
                </p>
                <p>
                  Travel to a high-tech cyber forensics lab and fusion center,
                  where a diverse team of MasterCard cybersecurity experts is
                  constantly investigating, safeguarding, and innovating against
                  threats like cyberattack and fraud.
                </p>
              </>
            }
          />
        </Row>
      </Container>
    </Section>
    <CareerCallout
      title="Vulnerability Analyst"
      description={
        <>
          Learn how <strong>cyberthreats</strong> can be analyzed and predicted
          to ensure that online businesses and their data are protected.
        </>
      }
      path="/career-profiles/vulnerability-analyst"
      img={'career-profiles/career-profiles-jazmine-headshot.jpg'}
      name="Jazmin Torres"
      job="Vulnerability Analyst, Mastercard"
    />
  </Layout>
);

export const query = graphql`
  query VideoTopicsQuery {
    allVideoTopicsYaml {
      edges {
        node {
          id
          actions {
            label
            actions {
              download
              label
              downloadCloseOnClick
            }
          }
          section
          englishSrc
          frenchSrc
          posterSrc
          descriptionTitle
          description
          episodeDesc
          title
          resourceTophat
          resourceImg
          resourceIcon
          resourceTitle
          resourceDesc
          resourceActions {
            dropdown
            label
            actions {
              download
              label
              downloadCloseOnClick
            }
          }
        }
      }
    }
  }
`;

export default VideoTopicPage;
