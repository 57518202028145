import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Row, Column } from '../Grid';
import Video from '../Video';
import Action from '../Resource/Action';
import Image from '../Image';
import Resource from '../Resource';
import Button from '../Button';
import Icon from '../Icon';
import { LANGUAGE } from '../../utils/constants';
import './styles.scss';

const EpisodePlayer = ({
  episodes,
  playerLabel,
  intro,
  className,
  showDropdown,
}) => {
  const [activeIndex, setActiveIndex] = useState(0); // zero-based index
  const [language, setLanguage] = useState('English');
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const viewerRef = useRef(null);

  const renderEpisodes = () => (
    <>
      {episodes.map((episode, index) => (
        <button
          key={index}
          className="episode-player__episode"
          onClick={() => {
            setActiveIndex(index);
            if (viewerRef.current.getBoundingClientRect()['top'] < 0) {
              viewerRef.current.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        >
          <div
            className={classNames(
              index === activeIndex && 'episode-player__episode--active'
            )}
          >
            <Image filename={episode.posterSrc} className="episode__image" />
          </div>
          <div>
            <h5>{episode.title}</h5>
            <p>{episode.episodeDesc}</p>
          </div>
        </button>
      ))}
    </>
  );

  const renderCurrentVideo = () => {
    const episode = episodes[activeIndex];
    let vidSrc = episode.englishSrc;

    if (language === 'English') {
      vidSrc = episode.englishSrc;
    } else if (language === 'French') {
      vidSrc = episode.frenchSrc;
    }

    return (
      <Video
        guidSrc={vidSrc}
        poster={episode.posterSrc}
        className="video-player"
        title={episode.title}
      />
    );
  };

  const renderCurrentData = () => {
    const episode = episodes[activeIndex];
    return (
      <Container fullWidth className="pl-0">
        <Row>
          <Column large={7}>
            <div className="episode-player__intro pb-2">
              <h3>{episode.descriptionTitle}</h3>
              <p>{episode.description}</p>
              {episode.actions && (
                <div className="episode-player__actions">
                  {episode.actions.map((action, i) => (
                    <div className="resource__actions">
                      <Action
                        key={i}
                        dropdown={true}
                        label={action.label}
                        actions={action.actions}
                      />
                    </div>
                  ))}
                </div>
              )}
              {intro}
            </div>
          </Column>
          <Column large={4}>
            <Resource
              tophat={episode.resourceTophat}
              img={episode.resourceImg}
              icon={episode.resourceIcon}
              title={episode.resourceTitle}
              description={episode.resourceDesc}
              actions={episode.resourceActions}
            />
          </Column>
        </Row>
      </Container>
    );
  };

  const languageClick = (lang) => {
    setLanguage(lang);
    setDropdownOpen((state) => !state);
  };

  return (
    <Container className={`episode-player ${className}`}>
      <h2>{playerLabel}</h2>
      {showDropdown && (
        <div className="episode-player-dropdown__container">
          <Button
            onClick={() => setDropdownOpen((state) => !state)}
            className="resource__dropdown-toggle"
          >
            {language} &nbsp;{' '}
            <Icon
              name="caratdown"
              className={classNames(
                'resource__dropdown-carat',
                dropdownOpen && 'resource__dropdown-carat--open'
              )}
            />
          </Button>
          {dropdownOpen && (
            <div className="resource__dropdown-items">
              <div className="resource__action">
                <Button
                  onClick={() => languageClick('English')}
                  link
                  className="united-states-flag"
                >
                  {LANGUAGE.en}
                </Button>
              </div>
              <div className="resource__action">
                <Button
                  onClick={() => languageClick('French')}
                  link
                  className="canada-flag"
                >
                  {LANGUAGE.ca}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="episode-player__content-wrapper">
        <div ref={viewerRef} className="episode-player__video-wrapper">
          <div className="episode-player__video">{renderCurrentVideo()}</div>
          <div className="episode-player__video-desktop-spacer"></div>
        </div>
        <div className="episode-player__current-data">
          {renderCurrentData()}
        </div>
        <div className="episode-player__episode-wrapper">
          <div className="episode-player__episode-desktop-spacer"></div>
          <div className="episode-player__episodes">{renderEpisodes()}</div>
        </div>
      </div>
    </Container>
  );
};

EpisodePlayer.propTypes = {
  episodes: PropTypes.array.isRequired,
  intro: PropTypes.element,
  playerLabel: PropTypes.string,
  className: PropTypes.string,
};

EpisodePlayer.defaultProps = {
  playerLabel: '',
  className: '',
  showDropdown: false,
};

export default EpisodePlayer;
